import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      height: "80vh",
      width: "90vw",
      marginTop: "5vh",
      marginLeft: "5vw",
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.dark,
      padding: "5vh 5vw",
      textAlign: "center"
    },
    logo: {
        margin: "4vh 3vw",
        width: "15vw",
        [theme.breakpoints.down("sm")]: {
            width: "40vw",
          },
      },
      title:{
          padding: "4vh",
          color: theme.palette.secondary.dark,
          fontWeight: 900,
          fontSize: "2.5em",
          [theme.breakpoints.down("sm")]: {
            fontWeight: 400,
            fontSize: "1.5em",
          },
      },
      subtitle:{
        padding: "2vh",
        color: theme.palette.primary.contrastText,
        fontWeight: 300,
        fontSize: "1.3em"
    }
}));

function Espera(props) {
    const classes = useStyles()

    const [speed] = React.useState(3)

    //const {number} = props
    return (
        <div className={classes.root}>
             <img alt="logo" src="/img/logo.png" className={classes.logo}></img>
             <Typography className={classes.title}>
                 Estamos procesando su orden 
             </Typography>
             <Typography className={classes.subtitle}>
                 En un momento estaremos en camino
             </Typography>
             <img style={{animation: `spin ${speed}s linear infinite`}} src="/img/redIcon.png"  alt="img"  className={classes.logo}/>

        </div>
    )
}

export default Espera
