import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { Modal, Typography, Box, Tooltip, Button } from "@material-ui/core";

import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";

import Entregado from "./Entegado";
import Loading from "./Loading";
import Error from "./Error";
import Espera from "./Espera";

const DATOS = gql`
  query TrackOrderResponse($trackOrderInput: TrackOrderInput!) {
    getTrackOrder(trackOrderInput: $trackOrderInput) {
      deliveryName
      deliveryCellphone
      orderState
      officePosition {
        latitude
        longitude
      }
      deliveryPosition {
        latitude
        longitude
      }
      deliveryDestination {
        latitude
        longitude
      }
      journal {
        tripId
        deliveryName
        date
        eventName
        description
        id
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingTop: "2vh",
    height: "100vh",
    backgroundColor: theme.palette.primary.dark,
  },
  divMapContainer: {
    margin: theme.spacing(0, 2, 0, 2),
    position: "sticky",
    display: "inherit",
    width: "90w",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "lightgrey",
    height: "85vh",
    align: "center",
  },
  infoWindowTitle: {
    padding: "2vh 0",
    color: "red",
    fontWeight: 300,
    fontSize: "1.3em",
  },
  titleContainer: {
    backgroundColor: "black",
    height: "5%",
    paddingTop: "0.5vh",
    paddingBottom: "0.5vh",
  },
  title: {
    padding: "0vh 1vw",
    color: "red",
    fontWeight: 300,
    fontSize: "1em",
  },
  infoWindowText: {
    paddingTop: "0.5vh",
    color: "black",
    fontWeight: 100,
    fontSize: "1em",
  },
  logo: {
    margin: "1vh 3vw",
    width: "15vw",
    [theme.breakpoints.down("sm")]: {
      width: "40vw",
    },
  },
  location: {
    position: "absolute",
    top: "11vh",
    left: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
    width: "8%",
  },
  client: {
    position: "absolute",
    top: "17vh",
    left: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
    width: "8%",
  },
  user: {
    position: "absolute",
    top: "23vh",
    left: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
    width: "8%",
  },
  store: {
    position: "absolute",
    top: "29vh",
    left: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
    width: "8%",
  },
  route: {
    position: "absolute",
    top: "35vh",
    left: "20px",
    padding: "0 10px",
    height: "4vh",
    width: "max-content",
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    fontSize: "0.9em",
  },
  motorizadoInfoBox: {
    padding: "10px",
    position: "absolute",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "lightgrey",
    bottom: "8vh",
    left: "5%",
    maxWidth: "75%",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  salirInfoBox:{
    padding: "10px",
    fontSize: "1.4em",
    position: "absolute",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "lightgrey",
    bottom: "50%",
    left: "25%",
    width: "50%",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  callNumber: {
    color: "#f00",
    fontSize: "1.1em",
  },
  textoInfo: {
    color: theme.palette.primary.contrastText,
    padding: "0 1vw",
    lineHeight: "1",
    fontSize: "1em",
  },
}));

function Track(props) {
  const classes = useStyles();
  const { google } = props;

  //local variables
  const service = new google.maps.DistanceMatrixService();

  const [latitudCliente, setLatitudCliente] = React.useState(null);
  const [longitudCliente, setLongitudCliente] = React.useState(null);

  const [latitudInicial, setLatitudInicial] = React.useState(-0.21000068);
  const [longitudInicial, setLongitudInicial] = React.useState(-78.493335);
  const [latitud, setLatitud] = React.useState(latitudInicial);
  const [longitud, setLongitud] = React.useState(longitudInicial);
  const [mapZoom, setMapZoom] = React.useState(16);
  const [showingInfoWindow, setshowingInfoWindow] = React.useState(false);
  const [activeMarker, setactiveMarker] = React.useState({});
  const [transact, setTransact] = React.useState(null);
  const [externalId, setExternalId] = React.useState(null);
  const [who, setWho] = React.useState(0);

  const [isInitializeValues, setIsInitializeValues] = React.useState(true);

  const [mapInstance, setMapInstance] = React.useState(null);
  const [showRouteOnMap, setShowRouteOnMap] = React.useState(false);
  const [displayServiceInstance, setDisplayServiceInstance] = React.useState(
    null
  );
  const [dataToShowRoute, setDataToShowRoute] = React.useState([]);
  const [distanceRemaining, setDistanceRemaining] = React.useState("");
  const [timeRemaining, setTimeRemaining] = React.useState("");
  const [timeRemainingInSeconds, setTimeRemainingInSeconds] = React.useState(1000);

  const { loading, error, data } = useQuery(DATOS, {
    variables: {
      trackOrderInput: {
        transact,
        externalId,
      },
    },
    pollInterval: 5000,
  });

  const [openCall, setOpenCall] = React.useState(false);

  useEffect(() => {
    if (mapInstance !== null && data.getTrackOrder.deliveryDestination && !entregado()) {
      showOrderRoute();
      console.log("entra a mapInsntace data")
      // if (data.getTrackOrder.deliveryDestination) {
      //   const origin = {
      //     lat: data.getTrackOrder.officePosition.latitude,
      //     lng: data.getTrackOrder.officePosition.longitude,
      //   };
      //   const destination = {
      //     lat: data.getTrackOrder.deliveryDestination.latitude,
      //     lng: data.getTrackOrder.deliveryDestination.longitude,
      //   };
      //   getDistance(origin, destination);
      // }
    }
  }, [mapInstance, data]);

  useEffect(() => {
    if (dataToShowRoute && showRouteOnMap) {
      calculateAndDisplayRoute(mapInstance, dataToShowRoute);
    }
  }, [dataToShowRoute]);

  useEffect(() => {
    let initialized = false;
    let watchId = 0;
    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(function (position) {
        if (position.coords.latitude){initialized= true}
        setLatitudCliente(position.coords.latitude);
        setLongitudCliente(position.coords.longitude);
      });
    }
    return () => {
      if (navigator.geolocation !== {}) {
        // console.log("clean subscription", navigator.geolocation);
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  useEffect(() => {
    console.log("showROute", showRouteOnMap)
    let interval = null;
    if (showRouteOnMap) {
      interval = setInterval(() => {
        if (showRouteOnMap && data.getTrackOrder.deliveryDestination) {
          console.log("entra", data.getTrackOrder.deliveryDestination )
          const origin = {
            lat: data.getTrackOrder.deliveryPosition.latitude,
            lng: data.getTrackOrder.deliveryPosition.longitude,
          };
          const destination = {
            lat: data.getTrackOrder.deliveryDestination.latitude,
            lng: data.getTrackOrder.deliveryDestination.longitude,
          };
          getDistance(origin, destination);
        }
      }, 60000);
    }
    return () =>
      interval ? clearInterval(interval) : console.log("no interval");
  }, [showRouteOnMap]);

  useEffect(() => {
    if (props.location?.search) {
      const query = new URLSearchParams(props.location.search);
      if (query.get("q")) {
        setTransact(query.get("q").replace(/\s+|%20/g, "+"));
      } else {
        setTransact(null);
      }
      if (query.get("e")) {
        setExternalId(query.get("e").replace(/\s+|%20/g, "+"));
      } else {
        setExternalId(null);
      }
    }
  }, [props.location]);

  //FUNCTIONS
  const onMarkerClick = (props, marker, e) => {
    setactiveMarker(marker);
    setshowingInfoWindow(true);
  };

  const onMapClick = (props) => {
    if (showingInfoWindow) {
      setshowingInfoWindow(false);
    }
  };

  const entregado = () => {
    if (!data?.getTrackOrder?.journal) {
      setOpenCall(true);
    } else {
      setOpenCall(false);
    }
  };

  useEffect(() => {
    entregado();

    if (data?.getTrackOrder && who === 0) {
      recenter(0);
    }
  }, [data, entregado]);

  const recenter = (selected) => {
    if (data.getTrackOrder.deliveryPosition) {
      switch (selected) {
        case 0:
          setWho(0);
          setMapZoom(15);
          setLatitud(data.getTrackOrder.deliveryPosition.latitude);
          setLongitud(data.getTrackOrder.deliveryPosition.longitude);
          break;
        case 1:
          setWho(1);
          setMapZoom(16);
          setLatitud(data.getTrackOrder.deliveryDestination.latitude);
          setLongitud(data.getTrackOrder.deliveryDestination.longitude);
          break;
        case 2:
          setWho(2);
          if (mapZoom === 16) {
            setMapZoom(15);
          } else {
            setMapZoom(16);
          }
          setLatitud(latitudCliente);
          setLongitud(longitudCliente);
          break;
        case 3:
          setWho(3);
          if (mapZoom === 16) {
            setMapZoom(15);
          } else {
            setMapZoom(16);
          }
          setLatitud(data.getTrackOrder.officePosition.latitude);
          setLongitud(data.getTrackOrder.officePosition.longitude);
          break;
        default:
          break;
      }
    }
  };

  const handleMapReady = (mapProps, map) => {
    setMapInstance(map);
    calculateAndDisplayRoute(map, dataToShowRoute);
  };

  const calculateAndDisplayRoute = (map, data) => {
    const directionsService = new google.maps.DirectionsService();
    let directionsDisplay = displayServiceInstance;

    if (displayServiceInstance == null) {
      directionsDisplay = new google.maps.DirectionsRenderer();
      directionsDisplay.setMap(map);

      setDisplayServiceInstance(directionsDisplay);
    }

    const waypoints = data.map((item) => {
      return {
        location: { lat: item.lat, lng: item.lng },
        stopover: false,
      };
    });
    if (waypoints.length > 0) {
      const origin = waypoints.shift().location;
      const destination = waypoints.pop().location;
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          waypoints: waypoints,
          travelMode: "DRIVING",
        },
        (response, status) => {
          if (status === "OK") {
            directionsDisplay.setDirections(response);
            directionsDisplay.setOptions({ suppressMarkers: true });
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    }
  };

  const showOrderRoute = () => {
    const dataOrder = [
      {
        lat: data.getTrackOrder.officePosition.latitude,
        lng: data.getTrackOrder.officePosition.longitude,
      },
      {
        lat: data.getTrackOrder.deliveryDestination.latitude,
        lng: data.getTrackOrder.deliveryDestination.longitude,
      },
    ];
    console.log(dataOrder)
    setDataToShowRoute(dataOrder);
    setShowRouteOnMap(true);
  };

  const getDistance = (origin, destination) => {
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
        if (status !== "OK") {
          alert("Error was: " + status);
        } else {
          setTimeRemainingInSeconds(response.rows[0].elements[0].duration.value)
          setDistanceRemaining(response.rows[0].elements[0].distance.text);
          setTimeRemaining(response.rows[0].elements[0].duration.text);
        }
      }
    );
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (data) {
    if (
      (data.getTrackOrder.deliveryPosition === null &&
        data.getTrackOrder.journal) ||
      data.getTrackOrder.orderState === "Hello World"
    ) {
      return <Espera />;
    } else {
      if (data.getTrackOrder.deliveryPosition) {
        if (isInitializeValues) {
          if (data.getTrackOrder.deliveryPosition.latitude) {
            setLatitudInicial(data.getTrackOrder.deliveryPosition.latitude);
            setLongitudInicial(data.getTrackOrder.deliveryPosition.longitude);
            setLatitud(data.getTrackOrder.deliveryPosition.latitude);
            setLongitud(data.getTrackOrder.deliveryPosition.longitude);
          }
          setIsInitializeValues(false);
        }
      }

      return (
        <div className={classes.root}>
          <img alt="logo" src="/img/logo.png" className={classes.logo}></img>
          <div className={classes.divMapContainer}>
            <Map
              item
              xs={6}
              google={props.google}
              zoom={mapZoom}
              mapTypeControl={false}
              initialCenter={{ lat: latitudInicial, lng: longitudInicial }}
              center={{ lat: latitud, lng: longitud }}
              onReady={handleMapReady}
              onClick={(props, marker, event) => {
                onMapClick();
              }}
            >
              {latitudCliente && longitudCliente ? (
                <Marker
                  onClick={onMarkerClick}
                  title={" Usted se encuentra aquí  "}
                  status={2}
                  location={
                    "Lat: " + latitudCliente + " Long: " + longitudCliente
                  }
                  icon={{
                    url: "/img/ubicacionIcon.svg",

                    scaledSize: new google.maps.Size(40, 60),
                  }}
                  position={{
                    lat: latitudCliente,
                    lng: longitudCliente,
                  }}
                />
              ) : null}
              {data.getTrackOrder.deliveryPosition ? (
                <Marker
                  onClick={onMarkerClick}
                  title={" Pedido en camino  "}
                  motorizado={data.getTrackOrder.deliveryName}
                  telefono={data.getTrackOrder.deliveryCellphone}
                  status={0}
                  location={
                    "Lat: " +
                    data.getTrackOrder.deliveryPosition.latitude +
                    " Long: " +
                    data.getTrackOrder.deliveryPosition.longitude
                  }
                  icon={{
                    url: "/img/deliveryIcon.svg",

                    scaledSize: new google.maps.Size(40, 60),
                  }}
                  position={{
                    lat: data.getTrackOrder.deliveryPosition.latitude,
                    lng: data.getTrackOrder.deliveryPosition.longitude,
                  }}
                />
              ) : null}
              {data.getTrackOrder.deliveryDestination?.latitude ? (
                <Marker
                  onClick={onMarkerClick}
                  title={" Destino  "}
                  texto={true}
                  status={1}
                  location={
                    "Lat: " +
                    data.getTrackOrder.deliveryDestination.latitude +
                    " Long: " +
                    data.getTrackOrder.deliveryDestination.longitude
                  }
                  position={{
                    lat: data.getTrackOrder.deliveryDestination.latitude,
                    lng: data.getTrackOrder.deliveryDestination.longitude,
                  }}
                  icon={{
                    url: "/img/destinoIcon.svg",

                    scaledSize: new google.maps.Size(40, 60),
                  }}
                />
              ) : null}
              {data.getTrackOrder.officePosition ? (
                <Marker
                  onClick={onMarkerClick}
                  title={" Local que envía"}
                  texto={true}
                  status={3}
                  location={
                    "Lat: " +
                    data.getTrackOrder.officePosition.latitude +
                    " Long: " +
                    data.getTrackOrder.officePosition.longitude
                  }
                  icon={{
                    url: "/img/storeIcon.svg",

                    scaledSize: new google.maps.Size(40, 60),
                  }}
                  position={{
                    lat: data.getTrackOrder.officePosition.latitude,
                    lng: data.getTrackOrder.officePosition.longitude,
                  }}
                />
              ) : null}
              <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                <div>
                  <div className={classes.titleContainer}>
                    <p className={classes.title} align="center">
                      {activeMarker.title}
                    </p>
                  </div>

                  {activeMarker.motorizado ? (
                    <p
                      align="justify"
                      variant="caption"
                      className={classes.infoWindowText}
                    >
                      {"Hola, mi nombre es "}
                      <b>{activeMarker.motorizado}</b>
                      {", estoy en camino con su orden"}
                    </p>
                  ) : null}
                  {activeMarker.telefono ? (
                    <p
                      align="justify"
                      variant="caption"
                      className={classes.infoWindowText}
                    >
                      {"si necesita contactarme, mi número es: "}
                      <b>{activeMarker.telefono}</b>
                    </p>
                  ) : null}
                  {activeMarker.texto ? (
                    <p
                      align="justify"
                      variant="caption"
                      className={classes.infoWindowText}
                    >
                      <b>
                        {
                          "Su orden se encuentra en camino, seleccione la vista de motorizado para ver su ubicación en tiempo real"
                        }
                      </b>
                    </p>
                  ) : null}
                </div>
              </InfoWindow>
            </Map>
          </div>
          <Modal open={openCall}>
            <div>
              <Entregado />
            </div>
          </Modal>

          {data.getTrackOrder.deliveryPosition ? (
            <Tooltip
              className={classes.callURL}
              title="Ubicación del motorizado"
              enterDelay={400}
              leaveDelay={200}
            >
              <Button
                className={classes.location}
                onClick={() => {
                  recenter(0);
                }}
              >
                 <img src={"/img/delivery.svg"} style={{width:"100%"}}></img>
              </Button>
             
            </Tooltip>
          ) : null}
          {data.getTrackOrder.deliveryDestination ? (
            <Tooltip
              className={classes.callURL}
              title="Destino"
              enterDelay={400}
              leaveDelay={200}
            >
              <Button
                className={classes.client}
                onClick={() => {
                  recenter(1);
                }}
              >
                <img src={"/img/destino.svg"} style={{width:"100%"}}></img>
              </Button>
            </Tooltip>
          ) : null}
          {latitudCliente && longitudCliente ? (
            <Tooltip
              className={classes.callURL}
              title="Mi ubicación actual"
              enterDelay={400}
              leaveDelay={200}
            >
              <Button
                className={classes.user}
                onClick={() => {
                  recenter(2);
                }}
              >
                <img src={"/img/ubicacion.svg"} style={{width:"100%"}}></img>
              </Button>
            </Tooltip>
          ) : null}
          {data.getTrackOrder.officePosition ? (
            <Tooltip
              className={classes.callURL}
              title="Ubicación del Local"
              enterDelay={400}
              leaveDelay={200}
            >
              <Button
                className={classes.store}
                onClick={() => {
                  recenter(3);
                }}
              >
                <img src={"/img/local.svg"} style={{width:"100%"}}></img>
              </Button>
              
            </Tooltip>
          ) : null}
          {data.getTrackOrder.deliveryCellphone &&
          data.getTrackOrder.deliveryName ? (
            <Box className={classes.motorizadoInfoBox}>
              <Typography className={classes.textoInfo}>
                {"Hola, soy "}
                {data.getTrackOrder.deliveryName}
                {", estoy en camino con su orden. "}
                {"Si necesita contactarme, mi número es: "}
                <span>
                  <a
                    className={classes.callNumber}
                    href={`tel:${data.getTrackOrder.deliveryCellphone}`}
                  >
                    {data.getTrackOrder.deliveryCellphone}
                  </a>
                </span>
              </Typography>
              {distanceRemaining !== "" ? (
                <>
                  <p className={classes.textoInfo}>
                    Distancia restante: {distanceRemaining}
                  </p>
                  <p className={classes.textoInfo}>
                    Tiempo restante: {timeRemaining}
                  </p>
                </>
              ) : null}
            </Box>
          ) : null}
          {timeRemainingInSeconds < 120? (
            <Box className={classes.salirInfoBox}>
              <Typography className={classes.textoInfo}>
                Tu pedido está cerca, acércate  a recibirlo
              </Typography>
            </Box>
          ) : null}
        </div>
      );
    }
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDgsuPP6lq7UZnKlxnC5bTAKvRiQe-He74",
})(Track);
