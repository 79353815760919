import React from "react";
import "./App.css";

import { ApolloProvider } from "@apollo/react-hooks";

import { BrowserRouter as Router, Route } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";

import Track from "./components/Track";

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={props.client}>
        <CssBaseline />
        <Router>
          <div className="App">
            <Route exact path="/" component={Track} />
          </div>
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
